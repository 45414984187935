// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";

// Components
import Layout from "components/Layout";
import LoadingAnimation from "components/LoadingAnimation";
import PostCard from "components/PostCardv2";

const BlogPageTitle = styled("h2")`
    margin-top: 0.5em;
`

const Posts = styled("div")`
    display: flex;
    flex-direction: column;
    margin-top: 1em;
`

const Blog = ({ posts, meta }) => (
    <>
        <Helmet
            title={`Blog`}
            titleTemplate={`%s – ${meta.title}`}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: `Blog – ${meta.title}`,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `Blog – ${meta.title}`,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },
            ].concat(meta)}
        />

        <LoadingAnimation>
        
                <BlogPageTitle>Blog</BlogPageTitle>
                
                <Posts>
                    {posts.map((post, i) => (
                        <PostCard
                            key={i}
                            author={post.node.post_author}
                            category={post.node.post_category}
                            title={post.node.post_title}
                            date={post.node.post_date}
                            description={post.node.post_preview_description}
                            uid={post.node._meta.uid}
                        />
                    ))}
                </Posts>
        </LoadingAnimation>
    </>
);

export default ({ data }) => {
    const posts = data.prismic.allPosts.edges;
    const meta = data.site.siteMetadata;
    
    if (!posts) return null;

    return (
        <Layout>
            <Blog posts={posts} meta={meta}/>
        </Layout>
    );
}

// Ensure prop types
Blog.propTypes = {
    posts: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};

// GraphQL query
export const query = graphql`
    {
        prismic {
            allPosts(sortBy: post_date_DESC) {
                edges {
                    node {
                        post_title
                        post_date
                        post_category
                        post_preview_description
                        post_author
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`

